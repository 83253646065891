<template>
  <div class="container">
    <header-back name='修改折扣'>
      <!-- <template slot="seachRight">
        <span @click="onRight">操作须知 </span>
      </template> -->
    </header-back>
    <section class="top44">
      <!-- <van-tabs v-model="activeName">
        <van-tab
          title="商户管理"
          name="1"
        > -->
      <div class="team-cen">

        <div class="team1">
          <div class="team2-t mt16">
            <div class="team2-tl">
              <div class="tem2-t-text">{{name}}</div>
              <!-- <div v-if="type!==4">{{type}}级代理</div> -->
              <div v-if="type!==4">下级代理</div>
              <div v-else>操作员</div>
            </div>
            <div class="team2-tr">
              <div class="tem2-t-text">状态</div>
              <div>
                <van-switch
                  v-model="checked"
                  active-color="#1AFA29"
                  size="20px"
                  @change="onSwitchChange($event)"
                />
              </div>
            </div>
          </div>
          <div class="team2-m">
            <div class="team2-head">

              <div
                class="team2-th"
                v-for="(item,index) in head"
                :key='index'
              >{{item}}</div>

            </div>
            <div
              class="team2-m-cen"
              v-if="headList.length"
            >

              <div
                class="team2-list"
                v-for="(item,index) in headList"
                :key='index'
              >
                <div class="team2-list-name">{{item.discountName}}</div>
                <div class="team2-list-def"> <span>{{item.userDiscountAmount}} %</span></div>
                <div class="team2-list-dic">
                  <van-cell-group v-if="userType < 3">
                    <van-field
                      v-model="item.childUserDiscountAmount"
                      placeholder="请输入"
                      type="digit"
                      :border="false"
                      class="tem2-input"
                      style="background: #E72B93;"
                      @input='onInput($event,item)'
                    >
                      <template #right-icon>
                        <span style="color:#fff">%</span>
                      </template>
                    </van-field>
                  </van-cell-group>
                  <span v-else>{{item.childUserDiscountAmount}}</span>
                </div>

                <div class="team2-list-btn">
                  <!-- :disabled='item.updatePermission===1' -->
                  <van-switch
                    v-model="item.checked"
                    active-color="#1AFA29"
                    size="16px"
                    @change="onHeadhSwitchChange($event,item)"
                  />
                  <!-- <van-button
                    type="primary"
                    round
                    :color="variables.textColor"
                    @click="onSaveClick"
                    size="mini"
                  >保存</van-button> -->
                </div>
              </div>

            </div>
            <div
              class="team2-m-cen"
              v-else
            >
              <p style="text-align: center;padding:20px;color:#999;font-size:12px">暂无数据</p>

            </div>
            <div
              class="team2-btn"
              v-if="headList.length"
            >
              <van-button
                :color="variables.textColor"
                size="small"
                round
                type="info"
                @click="saveClick"
              >批量保存</van-button>
            </div>
          </div>
        </div>
      </div>
      <!-- </van-tab> -->
      <!-- <van-tab
          title="批量设置"
          name="2"
        >
          <div class="team-cen">
            <van-search
              v-model="seachValue2"
              shape="round"
              background="transparent"
              placeholder="输入品牌、系列、型号搜索"
              @search='onSeach2'
            />
            <div class="team2">
              <div class="team2-h">

                <div
                  class="team2-ht"
                  :class="{'htActive':tagIndex===index}"
                  v-for="(item,index) in tagList"
                  :key='index'
                  @click="tagCLick(index)"
                >{{item.name}}</div>

              </div>
              <div class="team2-m">
                <div class="team2-head">

                  <div
                    class="team2-th"
                    v-for="(item,index) in head"
                    :key='index'
                  >{{item}}</div>

                </div>
                <div class="team2-m-cen">

                  <div
                    class="team2-list"
                    v-for="(item,index) in headList"
                    :key='index'
                  >
                    <div class="team2-list-name">{{item.name}}</div>
                    <div class="team2-list-def"> <span>{{item.discountDef}}</span></div>
                    <div class="team2-list-dic">
                      <van-cell-group>
                        <van-field
                          v-model="item.discount"
                          placeholder="请输入"
                          :border="false"
                        />
                      </van-cell-group>
                    </div>
                    <div class="team2-list-btn">
                      <van-button
                        type="primary"
                        round
                        :color="variables.textColor"
                        @click="onSaveClick"
                        size="mini"
                      >保存</van-button>
                    </div>
                  </div>

                </div>
                <div class="team2-btn">
                  <van-button
                    :color="variables.textColor"
                    size="small"
                    round
                    type="info"
                    @click="saveClick"
                  >批量保存</van-button>
                </div>
              </div>
            </div>
          </div>
        </van-tab> -->

      <!-- </van-tabs> -->
    </section>
    <van-popup
      v-model="operateShow"
      :round='true'
      :style="{ width: '90%' }"
    >
      <div class="operate-main">
        <p class="notice_tit_P01">使用说明</p>
        <div class="notice_p_P01">
          <p>1、折扣管理是由【默认注册折扣】和【批量设置折扣】两部分组成。折扣设置保留利润点区间为0-0.1。（例如账户折扣为0.78，可设置的区间为0.78-0.68)</p>
          <p>2、【默认注册折扣】为通过管理员账号发送的注册链接、二维码以及直接添加的方式产生的用户。如果管理员没有设置【批量设置折扣】，用户注册成功之后，系统将按照管理员设置的【默认注册折扣】进行做单结算。</p>
          <p>3、【批量设置折扣】为管理员账户的所有成员做单的最终折扣(单独对某个成员设置折扣除外)，如果管理员账户已对通道进行【批量设置折扣】操作，系统将按照设置的【批量设置折扣】进行做单结算。</p>
          <p>4、设置折扣需要在相应通道【设置框】中填写并勾选【折扣确认】，待设置完毕点击【保存】，折扣设置完成。</p>
        </div>
        <div class="notice-btn">
          <van-button
            :color="variables.textColor"
            size="small"
            round
            type="info"
            @click="operateClick"
          >确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import API from "../../api/http";
import variables from "@/styles/variables.scss";
export default {
  components: { HeaderBack },
  data() {
    return {
      activeName: "1",
      seachValue2: "",
      name: "",
      type: "",
      childUserId: "",
      checked: false,
      tagIndex: 0,
      tagList: [
        {
          name: "默认注册折扣",
          type: "1",
        },
        {
          name: "批量设置折扣",
          type: "2",
        },
      ],
      head: ["渠道", "我的折扣", "代理折扣", "状态"],
      headList: [
        // {
        //   name: "农夫山泉",
        //   id: "1",
        //   discount: "0.00",
        //   discountDef: "0.5",
        //   checked: true,
        // },
        // {
        //   name: "农夫山泉",
        //   id: "1",
        //   discount: "0.5",
        //   discountDef: "0.00",
        //   checked: true,
        // },
      ],
      operateShow: false,
      oldHeadList: [], //保存列表（代理折扣）数据
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userType() {
      console.log("kkk", this.$store.getters["user/userType"]);
      return this.$store.getters["user/userType"];
    },
  },
  created() {
    this.getQuery();
  },
  methods: {
    getQuery() {
      this.name = this.$route.query.nickname;
      this.childUserId = this.$route.query.userId;
      this.type = Number(this.$route.query.userLevel);
      let status = Number(this.$route.query.status);
      this.checked = status === 0 ? true : false;
      this.getInit();
    },
    getInit() {
      API.getTeamChildList({ childUserId: this.childUserId }).then((res) => {
        let list = res.data || [];
        // updatePermission：0 可改 1 不可改
        this.headList = list.map((item) => {
          return {
            ...item,
            userDiscountAmount: item.userDiscountAmount * 100,
            childUserDiscountAmount: item.childUserDiscountAmount * 100,
            checked: item.childUserStatus === 0 ? true : false,
          };
        });
        this.oldHeadList = JSON.parse(JSON.stringify(this.headList));
      });
    },
    onSeach2() {},
    onSwitchChange(val) {
      console.log(val);
      let obj = {
        childUserId: this.childUserId,
        status: val ? 0 : 1,
      };

      API.getTeanmChildStatus(obj).then((res) => {
        Toast("修改状态成功");
      });
    },

    onSaveClick() {
      //单个保存
    },
    saveClick() {
      //批量保存\
      let flag = true;
      for (let item of this.headList) {
        if (
          Number(item.childUserDiscountAmount) > Number(item.userDiscountAmount)
        ) {
          flag = false;
          Toast("代理折扣不能高于我的折扣");
          return;
        }
      }
      if (flag) {
        //排除没有修改过的数据
        let list = [];
        let map = {};
        for (let v of this.oldHeadList) {
          map[v.discountConfigId] = v;
        }
        for (let v of this.headList) {
          let value = map[v.discountConfigId].childUserDiscountAmount;
          if (
            Number(value) !== Number(v.childUserDiscountAmount) ||
            map[v.discountConfigId].checked !== v.checked
          ) {
            list.push({
              discountConfigId: v.discountConfigId,
              status: v.checked ? 0 : 1,
              childUserDiscount: Number(v.childUserDiscountAmount) / 100,
            });
          }
        }

        if (list.length === 0) {
          return;
        }

        API.postTeamChildUpdata({
          childUserId: this.childUserId,
          data: list,
        }).then((res) => {
          Toast("修改成功");
          setTimeout(() => {
            this.getInit();
          }, 1000);
        });
      }
    },
    onInput(val, item) {
      if (Number(val) > Number(item.userDiscountAmount)) {
        Toast("代理折扣不能高于我的折扣");
      }
    },
    onHeadhSwitchChange(val, item) {},
    onRight() {
      this.operateShow = true;
    },
    operateClick() {
      this.operateShow = false;
    },
  },
};
</script>
<style  lang='scss'>
.team2-list-dic {
  .tem2-input input {
    color: #fff !important;
    text-align: center;
  }
}
</style>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  box-sizing: border-box;
}
.team-cen {
  .van-search__content {
    background-color: #fff;
  }
}
.team1,
.team2 {
  padding: 4px 14px 14px 14px;
  box-sizing: border-box;

  color: #3e3e3e;
  font-size: 16px;
}
.team1 {
  .team2-m {
    background: #fff;
    border-radius: 6px;
    margin-top: 16px;
  }
}
.team2-h,
.team2-head,
.team2-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.team2-ht {
  flex: 1;
  height: 45px;
  line-height: 45px;
  color: #717171;
  background: #e3e3e3;
  font-size: 14px;
}
.htActive {
  background-color: #e72d94;
  color: #fff;
}
.team2-th {
  flex: 1;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e5e5e5;
}

.team2-list > div {
  flex: 1;
  font-size: 12px;
}
.team2-list-def > span {
  background-color: #b6c1fa;
  color: #fff;
  display: inline-block;
  min-width: 40px;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
}
// .team2-list-dic > span {
//   background-color: #e72d94;
//   color: #fff;
//   display: inline-block;
//   min-width: 40px;
//   height: 32px;
//   line-height: 32px;
//   padding: 0 8px;
// }
// .team2-list-def > span {
//   background-color: #b6c1fa;
//   color: #fff;
//   padding: 6px 15px;
// }
.team2-list-dic {
  .van-cell {
    padding: 4px 10px;
    font-size: 14px;
  }
}

.team2-list-btn .van-button {
  padding-left: 16px;
  padding-right: 16px;
}
.team2-btn {
  padding: 16px;
  text-align: center;
}

.team2-th {
  flex: 1;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #e5e5e5;
}
.team2-list {
  height: 45px;
  // line-height: 45px;
  border-bottom: 1px solid #e5e5e5;
}

.team2-list > div {
  flex: 1;
  font-size: 14px;
}

.team2-list-dic .van-cell {
  padding: 4px 10px;
  font-size: 14px;
}
.team2-btn {
  margin-top: 60px;
  text-align: center;
}
.team2-t {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 120px;
}
.team2-t > div {
  flex: 1;
  font-size: 14px;
  color: #7e7e7e;
}
.tem2-t-text {
  padding-bottom: 6px;
  font-size: 16px;
  color: #3e3e3e;
}
.team2-tl {
  position: relative;
  border-right: 1px solid #e4e4e4;
}
.operate-main {
  padding: 12px;
  .notice_tit_P01 {
    color: #333333;
    font-size: 16px;
    text-align: center;
    padding-bottom: 10px;
  }
  .notice_p_P01 {
    font-size: 12px;
    color: #999999;
    line-height: 20px;
    P {
      padding: 4px 0;
    }
  }
  .notice-btn {
    text-align: center;
    .van-button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
